<template>
  <div>
    <b-modal
      :id="`create-edit-${modal}`"
      title="Permission Assignment"
      :ok-title="permission ? 'Update' : 'Next'"
      no-close-on-backdrop
      @hidden="clearForm"
      @shown="getCurrentStep"
    >
      <div v-if="user">
        <p class="mb-0">
          Permission Assignment to {{ user.name }}
        </p>
      </div>
      <div v-else-if="permissionInfo">
        <p class="mb-0">
          {{ permissionInfo.description }}
        </p>
        <h5>{{ permissionInfo.slug }}</h5>
      </div>
      <hr>

      <b-overlay :show="isProcessing">
        <!-- Permission To -->
        <validation-observer ref="permissionForm">
          <radio-form
            v-if="steps[currentStep].step === 'permission-to'"
            v-model="form.permissionTo"
            label="Permission To"
            name="permission-to"
            :options="permissionToOptions"
            :is-processing="isProcessing"
            :selected="form.permissionTo"
          />

          <div
            v-if="steps[currentStep].step === 'permissible'"
          >
            <!-- Permissible // Permission List-->
            <div v-if="user">
              <validation-provider
                #default="{errors}"
                name="permission"
                rules="required"
              >
                <b-form-group label="Select a Permission">
                  <v-select
                    v-model="form.permission"
                    :state="errors.length > 0 ? false : null"
                    :options="permissionList"
                    :reduce="e => e.value"
                    placeholder="Search Permissions"
                    class="my-1"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </div>

            <div v-else>
              <!-- Company Roles -->
              <radio-form
                v-if="form.permissionTo === 'Company Roles'"
                v-model="form.role"
                label="Company Roles"
                name="company-roles"
                :options="companyRolesOptions"
                :is-processing="isProcessing"
                :selected="form.role"
              />

              <!-- Project Roles -->
              <radio-form
                v-if="form.permissionTo === 'Project Roles'"
                v-model="form.role"
                label="Project Roles"
                name="project-roles"
                :options="projectRolesOptions"
                :is-processing="isProcessing"
                :selected="form.role"
              />

              <!-- User Selection -->
              <validation-provider
                v-if="form.permissionTo === 'User'"
                #default="{ errors }"
                name="User"
                rules="required"
              >
                <b-form-group label="Search User">
                  <v-select
                    v-model="form.user"
                    :state="errors.length > 0 ? false : null"
                    :options="userOptions"
                    :reduce="e => e.value"
                    placeholder="Search User"
                    class="my-1"
                    taggable
                    @search="fetchUsers"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <!-- Company/Project Selection -->
          <radio-form
            v-if="steps[currentStep].step === 'company-project-selection' && isNotUserPermissionEdit"
            v-model="form.target"
            label="Target"
            name="target"
            :options="targetOptions"
            :selected="form.target"
          />

          <!-- Permission type -->
          <div
            v-if="isLastStep"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="form.allow"
              class="colorful-switch my-1"
              button-variant="success"
              :value="true"
              :unchecked-value="false"
              switch
              pill
            >
              <span class="switch-icon-left">
                <feather-icon
                  icon="CheckIcon"
                  color="#ffffff"
                />
              </span>
              <span class="switch-icon-right">
                <feather-icon
                  icon="XIcon"
                  color="#ffffff"
                />
              </span>
            </b-form-checkbox>
            <h5
              class="ml-1 mb-0 cursor-pointer"
              @click="() => { form.allow = form.allow ? false : true }"
            >
              Allow
            </h5>
          </div>
        </validation-observer>
      </b-overlay>

      <template #modal-footer>
        <b-button
          v-if="isNotUserPermissionEdit"
          variant="secondary"
          :disabled="currentStep === 1 || (user && currentStep === 2)"
          @click="() => { currentStep-- }"
        >
          Back
        </b-button>
        <b-button
          variant="primary"
          @click="nextStep"
        >
          <span v-if="isLastStep">
            {{ permission ? 'Update' : 'Submit' }}
          </span>
          <span v-else>Next</span>
        </b-button>
      </template>
    </b-modal>

    <!-- Confirmation Modal -->
    <b-modal
      :id="`${modal}-confirmation`"
      title="Confirm Permission Assignment"
      ok-title="Confirm"
      no-close-on-backdrop
      @ok="handleSubmit"
    >
      <div class="d-flex">
        <feather-icon
          class="mr-1"
          icon="AlertCircleIcon"
          size="46"
        />
        <div>
          <p v-if="permissionOptions.permissionTo && (permissionInfo || permissionDescription)">
            <span class="text-capitalize">{{ permissionOptions.permissionTo }}</span> will
            {{ !form.allow ? 'not' : '' }}
            be able to
            <span class="text-lowercase">{{ permissionOptions.description }}</span>
            in {{ permissionOptions.target }}.
          </p>
          Are you sure you want to {{ permissionOptions.action }}
          <span class="font-weight-bold">{{ permissionOptions.permission }}</span>
          permission {{ form.allow ? 'to' : 'from' }}
          {{ permissionOptions.permissionTo }}?
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BFormGroup, BFormCheckbox, BButton, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'
import vSelect from 'vue-select'
import _ from 'lodash'
import RadioForm from '@/views/common/components/RadioForm.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    RadioForm,
  },
  props: {
    permission: {
      type: Object,
      default: () => null,
    },
    permissionInfo: {
      type: Object,
      default: () => null,
    },
    permissionList: {
      type: Array,
      default: () => null,
    },
    modal: {
      type: String,
      default: () => 'permission',
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      form: {
        permissionTo: 'Company Roles',
        role: null,
        project: null,
        target: 'Company',
        allow: true,
        permission: '',
      },
      permissionToOptions: [
        { label: 'Company Roles', value: 'Company Roles', details: 'Give permission to a company role' },
        { label: 'Project Roles', value: 'Project Roles', details: 'Give permission to a project role' },
        { label: 'User', value: 'User', details: 'Give permission to a user' },
      ],
      steps: [
        { id: 0, step: 'nothing' },
        { id: 1, step: 'permission-to' },
        { id: 2, step: 'permissible' },
        { id: 3, step: 'company-project-selection' },
      ],
      currentStep: 0,
      companyRoles: [],
      projectRoles: [],
      users: [],
      isProcessing: false,
      isLoading: false,
      permissionDescription: null,
      currentCompany: this.$store.state.project.company.name,
      currentProject: this.$store.state.project.project.name,
    }
  },
  computed: {
    targetOptions() {
      return [
        { label: `Company - ${this.$store.state.project.company.name}`, value: 'Company' },
        ...(this.form.role !== 'agent' ? [{ label: `Project - ${this.$store.state.project.project.name}`, value: 'Project' }] : []),
      ]
    },
    companyRolesOptions() {
      return this.companyRoles?.map(role => ({
        label: role.displayName,
        value: role.name,
      }))
    },
    projectRolesOptions() {
      return this.projectRoles?.map(role => ({
        label: role.displayName,
        value: role.name,
      }))
    },
    userOptions() {
      return this.users?.map(user => ({
        label: user.name,
        value: user.uuid,
      }))
    },
    isLastStep() {
      if (this.steps[this.currentStep].step === 'company-project-selection') return true
      return false
    },
    isNotUserPermissionEdit() {
      return !this.user || (this.user && !this.user.permission)
    },
    permissionOptions() {
      const action = this.form.allow ? 'give' : 'revoke'
      const description = this.permissionInfo ? this.permissionInfo.description : this.permissionDescription
      const target = this.form.target === 'Company' ? `the company - ${this.currentCompany}` : `the project - ${this.currentProject}`
      const permission = this.permissionInfo ? this.permissionInfo.slug : this.form.permission

      let permissionTo = ''
      switch (this.form.permissionTo) {
        case 'Company Roles':
          permissionTo = `company ${this.form.role}s`
          break
        case 'User':
          if (this.user) {
            permissionTo = this.user.name
          } else if (this.form.user) {
            permissionTo = this.users.find(user => user.uuid === this.form.user).name
          }
          break
        default:
          permissionTo = `project ${this.form.role}s`
      }

      return {
        action, description, target, permission, permissionTo,
      }
    },
  },
  watch: {
    permission: {
      handler(val) {
        if (val) {
          this.form.allow = val.allow
          // eslint-disable-next-line no-underscore-dangle
          this.form.project = val.permitted.__typename === 'Project' ? val.permitted.uuid : ''

          // eslint-disable-next-line no-underscore-dangle
          switch (val.permissible.__typename) {
            case 'UserRole':
              this.form.permissionTo = 'Company Roles'
              break
            case 'ProjectRole':
              this.form.permissionTo = 'Project Roles'
              // eslint-disable-next-line no-underscore-dangle
              this.form.target = val.permitted.__typename
              break
            case 'User':
              this.users = [val.permissible]
              this.form.permissionTo = 'User'
              this.form.user = val.permissible.uuid
              // eslint-disable-next-line no-underscore-dangle
              this.form.target = val.permitted.__typename
              break
            default:
          }
        }
      },
      immediate: true,
    },
    permissionInfo: {
      handler(val) {
        if (val) this.form.slug = val.slug
      },
      immediate: true,
    },
    user: {
      handler(val) {
        if (val) {
          this.form.user = val.uuid
          this.form.permissionTo = 'User'
          this.form.permission = val.permission
          this.form.target = val.permissionLevel === 'USERPROJECT' ? 'Project' : 'Company'
          this.form.allow = val.allow ?? true
        }
      },
      immediate: true,
    },
    'form.permission': {
      handler(val) {
        if (val) this.permissionDescription = this.permissionList.find(permission => permission.value === val).label
        else this.permissionDescription = ''
      },
    },
    'form.permissionTo': {
      handler(val) {
        if (val === 'User') this.form.role = null
      },
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        projectUid: this.$store.state.project.selectedProject,
        companyUid: this.$store.state.project.selectedCompany,
        slug: this.user ? this.form.permission : this.permissionInfo?.slug,
        input: {
          action: this.form.allow ? 'ALLOW' : 'BLOCK',
        },
      }
      if (this.form.role) data.input.role = this.form.role
      else data.input.userUid = this.form.user

      if (this.form.target === 'Project') {
        useApollo.project.updateProjectPermission(data).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data.updateProjectPermission.message,
            },
          })
        }).finally(() => { this.$emit('updated') })
      } else if (this.form.target === 'Company') {
        useApollo.project.updateCompanyPermission(data).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data.updateCompanyPermission.message,
            },
          })
        }).finally(() => { this.$emit('updated') })
      }
      this.$bvModal.hide(`create-edit-${this.modal}`)
    },
    nextStep() {
      this.$refs.permissionForm.validate().then(success => {
        if (success) {
          if (this.isLastStep) {
            if (this.user) this.$bvModal.show('user-permission-confirmation')
            else this.$bvModal.show('permission-confirmation')
          } else {
            this.currentStep += 1
            switch (this.currentStep) {
              // Permissible
              case 2:
                if (this.form.permissionTo === 'User') {
                  if (!this.users.length) this.fetchUsers('')
                } else {
                  // clear the user if user is stored
                  if (this.form.user) this.form.user = null

                  if (this.form.permissionTo === 'Company Roles') this.getCompanyRoles()
                  else if (this.form.permissionTo === 'Project Roles') this.getProjectRoles()
                }
                break
              default:
            }
          }
        }
      })
    },
    clearForm() {
      this.form = {
        permissionTo: 'Company Roles',
        role: null,
        project: null,
        target: 'Company',
        allow: true,
        permission: '',
      }
      this.currentStep = 0
      this.$emit('close')
    },
    getRoles(event) {
      if (event === 'Company Roles') this.getCompanyRoles()
      else if (event === 'Project Roles') this.getProjectRoles()
    },
    getCompanyRoles() {
      if (!this.companyRoles.length) {
        this.isProcessing = true
        useApollo.company.getUserRoles().then(response => {
          this.companyRoles = response.data.roles.data
          this.form.role = this.form.role ?? this.companyRoles[0]?.name
        }).finally(() => { this.isProcessing = false })
      } else this.form.role = this.form.role ?? this.companyRoles[0]?.name
    },
    getProjectRoles() {
      if (!this.projectRoles.length) {
        this.isProcessing = true
        useApollo.project.getProjectRole().then(response => {
          this.projectRoles = response.data.projectRoles.data
          this.form.role = this.form.role ?? this.projectRoles[0]?.name
        }).finally(() => { this.isProcessing = false })
      } else this.form.role = this.form.role ?? this.projectRoles[0]?.name
    },
    fetchUsers: _.debounce(function (event) {
      if (event || !this.form.user) {
        this.isLoading = true
        useApollo.users.searchUserWithProjects({
          keyword: event,
          projectUid: this.$store.state.project.selectedProject,
          first: 5,
        }).then(response => {
          this.users = response.data.users.data
          this.isLoading = false
        })
      }
    }, 1000),
    getCurrentStep() {
      // Start from Step 2 for user level permissions
      if (this.user) {
        if (this.user.permission) this.currentStep = 3
        else this.currentStep = 2
      } else this.currentStep = 1
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-switch {
  scale: 1.2;
}
</style>

<style lang="scss">
  @import "@core/scss/base/pages/ui-colors.scss";
</style>
